
.service-area-menu ul li {
  display: inline-block;
  padding: 10px 0;
}

.service-area-menu ul {
  justify-content: space-around;
}

.service-area-menu li a {
  text-align: center;
  padding: 12px 81px;
  color: #FFF;
  border-right: 1px solid #EEE;
}

.service-area-menu li:last-child a {
  border-right: none;
}

.service-area-menu li a.active,
.service-area-menu li a:hover {
  background: #4E7B72;
  color: #FFF;
}

.service-area-menu ul {
  margin-top: 50px;
  background: #649E93;
}

.ceromony-content2 {
  padding-left: 30px;
}

.Ceremony-wrap {
  margin-top: 30px;
  background: #F5FBFB;
}

#people .Ceremony-wrap {
  background: none;
}

#people .Ceremony-wrap {
  margin-top: 0;
}

.service-area2 .Ceremony-wrap {
  background: #F5FBFB;
}

.ceromony-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.ceromony-content h3 {
  font-size: 30px;
  color: #649E93;
  margin-bottom: 16px;
}

.ceromony-content a {
  color: #649E93;
  font-weight: 600;
}

.ceromony-content span {
  display: block;
}

.ceromony-content p {
  padding: 20px 0;
  padding-right: 20px;
}

